import * as React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout";
import {StaticImage} from "gatsby-plugin-image";
import GooglePlayButton, {zukanGooglePlayUrl} from "src/components/common/google_play_button";
import AppleStoreButton, {zukanAppStoreUrl} from "src/components/common/apple_store_button";
import {BackHome} from "src/components/common/back_home";
import {graphql} from "gatsby";
import "src/styles/products/main.scss";

const title = "はじめての図鑑〜動物・車・飛行機など言葉を学べる知育アプリ";
const description = "はじめての図鑑」は、1歳から5歳の子供向けの無料知育アプリです。かわいいイラストを使って、遊びながら日本語と英語の言葉を学べます。シンプルな操作で赤ちゃんから使え、タップするだけで動物や乗り物の名前を日英両語で表示。楽しく遊びながら言語習得と創造力を養える、幼児教育に最適なアプリです。";

const MyFirstLibrary = () => {
  return (
    <Layout>
      <Seo title={title} description={description} pagePath={"/product/my_first_library"}/>
      <article className="container article-container">
        <header className="article-header"><h1>{title}</h1>
        </header>
        <section>
          <h2>はじめての図鑑について</h2>
          <p>「はじめての図鑑」は、かわいいイラストの動物や乗り物などをタッチして遊びながら、言葉を覚えられる知育アプリです。</p>
          <StaticImage
            src={"../../../images/zukan/zukan_feature_graphic.png"}
            alt="はじめての図鑑アプリの紹介"
          />

          <h2>「はじめての図鑑」の機能や特徴</h2>
          <p>「はじめての図鑑」は子どもの言葉の習得をサポートする知育アプリです。<br/>
            楽しみながらアプリを遊ぶ中で自然にいろいろな言葉を覚えることができます。
          </p>

          <h3>かわいいイラストと音声付きで言葉を覚えられる</h3>
          <p>動物や乗り物のイラストをタップすると、名前が日本語と英語で表示されます。いろいろなイラストにタップする中で、ひらがなとアルファベットに自然と触れることができます。</p>
          <p>動物や乗り物の名前には、読み上げ機能がついています。読み上げは日本語だけでなく英語に切り替えも可能。遊びを通して自然に英語にふれることができます。</p>

          <h3>いつでもどこでも遊べる</h3>
          <p>お出かけの際に絵本やおもちゃをたくさん持っていくのは大変…「はじめての図鑑」はスマートフォンのアプリなので、ママ・パパが持ち歩くスマートフォン一にインストールしておけば、いつでもすぐに遊べます。</p>
          <p>また初回ダウンロード以降は、いつでもオフラインの環境でアプリをお楽しみいただけます（一部機能を除く）。外出先で通信環境が悪い場合でも安心です。</p>

          <h3>広告なし</h3>
          <p>完全広告非表示です。ママ・パパが手を離せないときでも安心して遊ばせられます。<br/>
            もちろん、ご家族で一緒に楽しみながら親子のコミュニケーションをとる道具としてもご活用いただけます。
          </p>

          <h2>対象年齢</h2>
          <p>アプリは、赤ちゃんから幼児・子供まで幅広い年齢層のお子様に遊んでいただけるようになっています。</p>
          <ul>
            <li>1歳、2歳、3歳、4歳、5歳</li>
            <li>男の子、女の子</li>
            <li>赤ちゃん、幼児、保育園、幼稚園、入園前、入園準備のこども</li>
          </ul>
        </section>

        <section>
          <p>「はじめての図鑑」はiOS/Androidで提供しています。各ストアからダウンロードしてご利用ください。</p>
          <p className="text-center download-buttons">
            <AppleStoreButton url={zukanAppStoreUrl()}/>
            <GooglePlayButton url={zukanGooglePlayUrl()}/>
          </p>
        </section>
      </article>

      <BackHome/>
    </Layout>
  )
}

export default MyFirstLibrary

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
